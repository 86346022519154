





































import { defineComponent } from '@vue/composition-api'
import useModelValue from '@/@core/helpers/modelValue'
import KTextAreaInput from '@/@core/components/input/KTextAreaInput.vue'

export default defineComponent({
    components: { KTextAreaInput },
    name: 'AdditionalForm',
    setup(props, { emit, attrs }) {
        const modelValue = useModelValue({ emit, attrs })

        return {
            modelValue
        }
    }
})
